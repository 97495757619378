import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { AboutLayout, Desi, Div } from '../components/desi-bio.js';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = AboutLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "desi-maxwell"
    }}>{`Desi Maxwell`}</h2>
    <Div sx={{
      maxWidth: '300px',
      marginBottom: 3
    }} mdxType="Div">
  <Desi mdxType="Desi" />
    </Div>
    <p>{`Desi je odraščal v grofiji Antrim v Severni Irski. Po študiju na Univerzi Ulster, Teološkem seminarju Westminster in Teološkem seminarju Princeton je sedem let deloval v pastorski službi, naslednjih dvajset let pa je predaval na Biblijskem kolidžu v Belfastu. Takrat si je začel prizadevati, da bi učilnico prenesel v dnevno sobo, ter v ta namen skupaj s soprogo Heather ustanovil in začel voditi Xplorations, pobudo za krščansko poučevanje s poudarkom na judovskih koreninah krščanstva.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      